
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class AllSpecialities extends React.Component {
  render() {
    return (
      <div className="section section-nucleo-icons">
        <img alt="..." className="path" src={require("assets/img/path3.png")} />
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="12">
              <h2 className="title">Let's Build Your Next IT Solution</h2>
              <h4 className="description">
                If you can dream it, we can build it . Specialized in each aspect of IT and Technology world
                we have a solid experience in each and every system type that is out there.
                we can fix yours or make you a new one, we are passionate about working with technology, especially new ones
              </h4>

            </Col>
          </Row>
          <div className="blur-hover">
          
              <div className="icons-container blur-item on-screen mt-5">
                {/* Center */}
                <i className="icon tim-icons icon-coins" />
                {/* Right 1 */}
                <i className="icon icon-sm tim-icons icon-spaceship" />
                <i className="icon icon-sm tim-icons icon-money-coins" />
                <i className="icon icon-sm tim-icons icon-link-72" />
                {/* Right 2 */}
                <i className="icon tim-icons icon-send" />
                <i className="icon tim-icons icon-mobile" />
                <i className="icon tim-icons icon-wifi" />
                {/* Left 1 */}
                <i className="icon icon-sm tim-icons icon-key-25" />
                <i className="icon icon-sm tim-icons icon-atom" />
                <i className="icon icon-sm tim-icons icon-satisfied" />
                {/* Left 2 */}
                <i className="icon tim-icons icon-gift-2" />
                <i className="icon tim-icons icon-tap-02" />
                <i className="icon tim-icons icon-wallet-43" />
              </div>
              <span className="blur-hidden h5 text-primary">
              Experienced In All Types of Softwares
              </span>
           
          </div>
        </Container>
      </div>
    );
  }
}

export default AllSpecialities;
