
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.0.0";
import "assets/demo/demo.css";

import Index from "views/Index.jsx";
import LandingPage from "views/examples/LandingPage.jsx";
import RegisterPage from "views/examples/RegisterPage.jsx";
import ProfilePage from "views/examples/ProfilePage.jsx";
import AboutUsPage from "views/examples/AboutUsPage.jsx";
import ContactUsPage from "views/examples/ContactUsPage.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/Home" render={props => <Index {...props} />} />
      {/* <Route
        path="/landing-page"
        render={props => <LandingPage {...props} />}
      /> */}
      <Route
        path="/Register"
        render={props => <RegisterPage {...props} />}
      />
      {/* <Route
        path="/profile-page"
        render={props => <ProfilePage {...props} />}
      /> */}
      <Route
        path="/AboutUs"
        render={props => <AboutUsPage {...props} />}
      />
      <Route
        path="/ContactUs"
        render={props => <ContactUsPage {...props} />}
      />



      <Redirect from="/" to="/Home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
