import './index.css'
// import {Motion,spring} from "react-motion"

import React from "react";
import Bounce from 'react-reveal/Bounce';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    ListGroupItem,
    ListGroup,
    Container,
    Row,
    Col
  } from "reactstrap";

  // require('intersection-observer');

class BestBenifits extends React.Component {
  render() {
    return (
      <>


<br/>
<br/>

            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="5">
                {/* <Bounce left> */}
                  <img
                    alt="..."
                    style={{
                        transform: 'scaleX(-1)',
                        // animation: 'move-left-right 10s infinite'
                    }}
                    className="img-fluid floating moving-obj"
                    src={require("assets/img/pan1.png")}
                  />
                  {/* </Bounce> */}
                
                </Col>
                <Col md="6">
                
                  <div className="px-md-5">
                    <hr className="line-success" 
  style={{
                    backgroundColor: '#e14eca',
                  }}

                    />
                    <h3>Migrate To Online</h3>
                    <p>
                    In the digital age, it is absolutely essential for your business to have an online presence.
Whether it’s a website, an e-commerce platform, a mobile app or a full system.
Conveting your company to a software soloution will reap major benefits.
Even if your company does not conduct business online, customers and potential customers are expecting to see you online.
If they don’t see you there, you could be losing out on the opportunity to increase your customer base and get the word out about your business.
Make smart processors and computers do your job.
                    </p>
  
                  </div>
                </Col>
              </Row>
            </Container>

            <br/>
<br/>
<br/>
<br/>


            <Container className='flipped'>
              <Row className="row-grid justify-content-between">
               
                <Col md="6" className="hide_big">
                  <div className="px-md-5">
                    <hr className="line-success" 
  style={{
                    backgroundColor: '#ff8d72',
                  }}

                    />
                    <h3>Increase Sales</h3>
                    <p>
                    If you are a business owner, more visitors  leads to more potential sales.
That’s how your website will help you.
You can drive more people to your site by consistently updating and promoting the contents of your site.
The more informative your site is, the greater the possibility of increasing your sales.
                    </p>
  
                  </div>
                </Col>
                <Col md="5">
             {/* <Motion
             defaultStyle={{x:-200,opacity:0}}
             style={{x:spring(0),opacity:spring(1)}}
             >
{(style)=>(
  <img style={{opacity:style.opacity,}}
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/pan2.png")}
                  />

)}
            
               
                
                   </Motion> */}
                   {/* <Bounce left> */}
                   <img 
                    alt="..."
                    style={{
                        transform: 'scaleX(-1)',
                        // animation: 'move-left-right 10s infinite'
                    }}
                    className="img-fluid floating moving-obj"
                    src={require("assets/img/pan2.png")}
                  />
                  {/* </Bounce> */}
                </Col>
                <Col md="6" className="hide_small">
                  <div className="px-md-5">
                    <hr className="line-success" 
  style={{
                    backgroundColor: '#ff8d72',
                  }}

                    />
                    <h3>Increase Sales</h3>
                    <p>
                    If you are a business owner, more visitors  leads to more potential sales.
That’s how your website will help you.
You can drive more people to your site by consistently updating and promoting the contents of your site.
The more informative your site is, the greater the possibility of increasing your sales.
                    </p>
  
                  </div>
                </Col>

              </Row>
            </Container>

            <br/>
<br/>
<br/>
<br/>

            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="5">
                {/* <Bounce left> */}
                  <img
                    alt="..."
                    style={{
                        transform: 'scaleX(-1)',
                          //  animation: 'move-left-right 10s infinite'
                      
                    }}
                    className="img-fluid floating moving-obj"
                    src={require("assets/img/pan3.png")}
                  />
                    {/* </Bounce> */}
                
                </Col>
                <Col md="6">
                  <div className="px-md-5">
                    <hr className="line-success" 
                

                    />
                    <h3>Meet Customers Expectations</h3>
                    <p>
                    Now more than ever, your customers expect to be able to find information about your company online.
In fact, most consumers now do research online before they make a purchase, even if it’s to buy something from their local shop.
If you don’t have a website, you’re sending a message that your business is stuck in the Dark Ages, or that you’re not interested in finding new customers,
and neither of these are messages that you probably want to be sending.
                    </p>
  
                  </div>
                </Col>
              </Row>
            </Container>

            <br/>
<br/>
<br/>
<br/>
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <h1 className="text-center" style={{marginBottom:'-20px'}}>What Do We Offer?</h1>
                  <Row className="row-grid justify-content-center">
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-primary">
                          <i className="tim-icons icon-money-coins moving-icon" />
                        </div>
                        <h4 className="info-title">Low Prices</h4>
                        <hr className="line-primary" />
                        <p>
                        Here at Laperla we program out of passion not for profit.
                        Do what you love and everything else will follow.
                        We guarantee the lowest prices on the market.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-vector moving-icon" />
                        </div>
                        <h4 className="info-title">Off The Charts Quality</h4>
                        <hr className="line-warning" />
                        <p>
                          Using the latest technologies in the IT industry.
                          A well experienced team is focused on delivering a state of the art 
                          software solutions to your door, server or cloud. 
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-satisfied moving-icon" />
                        </div>
                        <h4 className="info-title">Clients Satisfaction</h4>
                        <hr className="line-success" />
                        <p>
                         With our after sale feedback. Flexible office hours and considering the client a part of Laperla family.
                         Your needs is our No.1 priority and your satisfaction is all what we seek. 
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
         
      </>
    );
  }
}

export default BestBenifits;
