import React from 'react'


const SEO = [
    'Website development Jordan',
    'development Jordan',
    'Website Company Jordan',
    'Mobile development Jordan',
    'Website Company Jordan',
    'IT Jordan',
    'شركة مواقع الكترونية الأردن الاردن',
    'شركة برمجيات الاردن',
    'تطبيقات هواتف الأردن  شركة مواقع الاردن الأردن',
    'تطبيقات هواتف الأردن الاردن  الأردن',
    'شركة مواقع الأردن الاردن '

]


export function JSXSEO() {

    for (var i = 0; i <= 50; i++) {
       return (
            <>

                {
                    [...Array(50).keys()].map(num => {
                           if (num % 2 == 0)
                                return (
                                    <div className="seo" style={{ display: 'none' }}>
                                        <p>
                                            {SEO[Math.floor(Math.random() * SEO.length)]}
                                        </p>
                                    </div>
                                );

                            else if( num % 3 == 0)
                                return (<span className="seo" style={{ display: 'none' }}>
                                    {SEO[Math.floor(Math.random() * SEO.length)]}
                                </span>)
                            else if( num % 5 == 0)
                                return (<h3  className="seo" style={{ display: 'none' }}>
                                    {SEO[Math.floor(Math.random() * SEO.length)]}
                                </h3>)

                            else
                                return (<p className="seo" style={{ display: 'none' }}>
                                    {SEO[Math.floor(Math.random() * SEO.length)]}
                                </p>)
                    })
                }







            </>
        )

    }

}


export function TXTSEO() {
    return (
        SEO[Math.floor(Math.random() * SEO.length)]
    )
}

