
import React from "react";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import { Line } from "react-chartjs-2";
import {JSXSEO,TXTSEO} from 'seo/seo.js'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
  CardTitle
} from "reactstrap";

import bigChartData from "variables/charts.jsx";

// core components
import Navbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

const carouselItems = [
  {
    src: require("assets/img/slide1.png"),
    altText: TXTSEO(),
    caption: ""
  },
  {
    src: require("assets/img/slide2.png"),
    altText: TXTSEO(),
    caption: ""
  },
  {
    src: require("assets/img/slide3.png"),
    altText:TXTSEO(),
    caption: ""
  }
];

let ps = null;

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1
    };
  }


  componentDidMount() {
    document.body.classList.toggle("landing-page");
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // let tables = document.querySelectorAll(".table-responsive");
      // for (let i = 0; i < tables.length; i++) {
      //   ps = new PerfectScrollbar(tables[i]);
      // }
    }
    document.body.classList.toggle("profile-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
    if (navigator.platform.indexOf("Win") > -1) {
      // ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };
  render() {
    return (
      <>
        <Navbar />
        <JSXSEO/>
        <div className="wrapper">
          <div className="page-header">
          <img
                alt={TXTSEO()}
              className="path"
              src={require("assets/img/blob.png")}
            />
            <img
                alt={TXTSEO()}
              className="path2"
              src={require("assets/img/path2.png")}
            />
            <img
               alt={TXTSEO()}
              className="shapes triangle"
              src={require("assets/img/triunghiuri.png")}
            />
            <img
               alt={TXTSEO()}
              className="shapes wave"
              src={require("assets/img/waves.png")}
            />
            <img
                alt={TXTSEO()}
              className="shapes squares"
              src={require("assets/img/patrat.png")}
            />
            <img
              alt={TXTSEO()}
              className="shapes circle"
              src={require("assets/img/cercuri.png")}
            />
            <Container style={{paddingTop:'17vh'}}>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      <h1 className="profile-title text-left">Contact Us</h1>
                      <h5 className="text-on-back">..</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Your Name</label>
                              <Input defaultValue="Mike" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email address</label>
                              <Input
                                placeholder="mike@email.com"
                                type="email"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Phone</label>
                              <Input defaultValue="001-12321345" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Company</label>
                              <Input defaultValue="CreativeTim" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Message</label>
                              <Input placeholder="Hello there!" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-round float-right"
                          color="primary"
                          data-placement="right"
                          id="tooltip341148792"
                          type="button"
                          onClick={()=>{
                              setTimeout(()=>{ alert("Thank For Submitting.\nWe Will Respond To You As Soon As Possible")},500)
                             
                              }}
                        >
                          Send text
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip341148792"
                        >
                          Can't wait for your message
                        </UncontrolledTooltip>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us at the office</h4>
                      <p>
                      <a href="https://goo.gl/maps/9KfRZer6jm9rTtx67" style={{color:'rgba(222, 222, 222, 0.8)'}}>
                        Makkah Al-Mukarramah Street 15, Amman,<br />
                        4Th Floor Pro1 Center  <br />
                        Office 107
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a call</h4>
                      <p>
                      <a href="tel:+962791630039 " style={{color:'rgba(222, 222, 222, 0.8)'}}>
                        Talal Ebdah <br />
                        +962 791 630 039 <br />
                        Sat - Thu, 8:00-20:00
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-email-85" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Email Us</h4>
                      <p>
                      <a href="mailto:info@laperla.tech" style={{color:'rgba(222, 222, 222, 0.8)'}}>
                        Talal Ebdah <br />
                        info@laperla.tech <br />
                        24/7 Support 
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <Footer />
        </div>
      </>
    );
  }
}

export default ContactUsPage;
