
import React from "react";
import Typist from 'react-typist';

// reactstrap components
import { Container } from "reactstrap";
const diamond= require('assets/img/diamondOnly.png')
class PageHeader extends React.Component {
  render() {
    return (
      <div className="page-header header-filter">
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="squares square7" />
        <Container>
          <div className="content-center brand">
            <h1 className="h1-seo">
            <Typist
            cursor={{
                  show: false,
                  // blink: true,
                  // element: '|',
                  // hideWhenDone: false,
                  // hideWhenDoneDelay: 1000,
            }}
            >
            <Typist.Delay ms={1000} />
<img src={diamond} style={{
height:'65px', width:'65px',marginTop:'-10px'
} }/>
            <span style={{fontFamily:"'Poppins'",
            fontWeight:'200'
            }}>LAPERLA</span>
            </Typist>
            
            </h1>
            <Typist
            cursor={{
                  show: false,
                  // blink: true,
                  // element: '|',
                  // hideWhenDone: false,
                  // hideWhenDoneDelay: 1000,
            }}
            >
            <Typist.Delay ms={1000} />
            <h3 className="d-none d-sm-block">
              A leading Software Development Agency Specializes in Websites, Mobile Apps And Software Development.
              </h3>
            </Typist>
          </div>
        </Container>
      </div>
    );
  }
}

export default PageHeader;
